<script setup lang="ts">
import { computed } from 'vue'
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { StatementAccount } from '/~/composables/statements'
import { useConfirmComponent } from '/~/modals/confirm/confirm'
import PayeeInfo from '/~/templates/bill-payments/views/statements/direct-debit/components/payee-info.vue'

const props = withDefaults(
  defineProps<{
    account: StatementAccount
    title: string
    description: string
    icon: {
      path: string
      color: string
    }
    showConfirm?: boolean
    showCancel?: boolean
    onConfirm?: () => Promise<void>
    onCancel?: () => Promise<void>
  }>(),
  {
    showConfirm: true,
    showCancel: true,
  }
)

const { confirming, cancelling, loading, onConfirm, onCancel } =
  useConfirmComponent({
    onConfirm: props.onConfirm,
    onCancel: props.onCancel,
    hide,
  })

function hide() {
  modal.hide()
}

const payeeTitle = computed(
  () =>
    (props.account.autoPayment?.statement as any)?.payeeName ||
    props.account.name
)
const subtitle = computed(() => `Account no. ${props.account.number}`)
</script>

<template>
  <base-mdl
    width="sme"
    border-radius="2xl"
    layout="v2"
    :title="title"
    :closable="!loading"
  >
    <template #icon>
      <div :class="icon.color">
        <base-icon :svg="icon.path" :size="80" class="mx-auto" />
      </div>
    </template>
    <div class="space-y-5 pb-12 pt-4">
      <payee-info
        :payee="selectedStatementAutoPayment?.statement?.payee"
        data-testid="payee-info"
        :title="payeeTitle"
        :subtitle="subtitle"
        class="w-full"
      />
      <div class="mb-8 mt-5 text-eonx-neutral-600" v-html="description" />
    </div>
    <div
      v-if="showConfirm || showCancel"
      :class="{ 'grid grid-cols-2 gap-2': showConfirm && showCancel }"
    >
      <base-button
        v-if="showCancel"
        :look="showConfirm ? 'outlined-color' : 'filled'"
        class="w-full"
        :disabled="loading"
        :loading="cancelling"
        @click="onCancel"
      >
        Cancel
      </base-button>
      <base-button
        v-if="showConfirm"
        class="w-full"
        :disabled="loading"
        :loading="confirming"
        @click="showCancel ? onConfirm() : hide()"
      >
        {{ showCancel ? 'Confirm' : 'Done' }}
      </base-button>
    </div>
  </base-mdl>
</template>
